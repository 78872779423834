import moment from 'moment'

export function isValidTimerGlassLocation(glass_location: Array<string>): boolean {
  const validValues = [
    'backlight: left barn door non-privacy',
    'backlight: left barn door privacy',
    'backlight non-privacy',
    'backlight privacy',
    'backlight: right barn door non-privacy',
    'backlight: right barn door privacy',
    'front windscreen',
  ]
  if (Array.isArray(glass_location)) {
    return glass_location?.some((value) => validValues?.includes(value))
  }
  return false
}

export function convertToISOWithTimezone(dateString: string): string {
  const date = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss')
  return date.format('YYYY-MM-DDTHH:mm:ss[Z]')
}

export function convertToLocalTimeNoTimeZone(dateString: string, formatResult = 'YYYY-MM-DD HH:mm:ss'): string {
  const convertWithTimeZone = convertToISOWithTimezone(dateString)
  return moment(convertWithTimeZone).format(formatResult)
}

export function isValidShowTextGlassLocation(glass_location: Array<string>): boolean {
  const validValues = [
    'backlight non-privacy',
    'backlight privacy',
    'backlight: left barn door non-privacy',
    'backlight: left barn door privacy',
    'backlight: right barn door non-privacy',
    'backlight: right barn door privacy',
    'front windscreen',
    'left middle quarter non-privacy',
    'left middle quarter privacy',
    'left rear body quarter non-privacy',
    'left rear body quarter privacy',
    'right middle quarter non-privacy',
    'right middle quarter privacy',
    'right rear body quarter non-privacy',
    'right rear body quarter privacy',
  ]
  if (Array.isArray(glass_location)) {
    return glass_location?.some((value) => validValues?.includes(value))
  }
  return false
}
