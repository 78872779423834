import './map.css'
import React, { FC, useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DirectionsRenderer, DirectionsService, GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api'
import { VanPosition } from 'src/core/models/van.model'
import { Address, Workshop } from '@glass/models'
import { LicensePlate } from '../LicensePlate'

type PropComponentMap = {
  showOnModal?: boolean
  onDismiss?: () => void
  deliveryAddress: Address
  workshop: Workshop
  vanPosition: VanPosition | null
  vanNumber?: string
  technicianName?: string
  isOnTheWay?: boolean
  setCallQuote: (value: boolean) => void
  callQuote: boolean
}

const MapTrackTechnicianComponent: FC<PropComponentMap> = ({
  showOnModal,
  workshop,
  deliveryAddress,
  vanPosition,
  vanNumber,
  technicianName,
  isOnTheWay,
  callQuote,
  setCallQuote,
}) => {
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [loaded, setLoaded] = useState(false)
  const oldVanPosition = useRef<VanPosition>()
  const [isInitMap, setIsInitMap] = useState(false)

  const vanLatLng = useMemo(
    () => (vanPosition ? { lat: vanPosition.latitude, lng: vanPosition.longitude } : undefined),
    [vanPosition],
  )
  const myPosition = useMemo(
    () =>
      deliveryAddress ? { lat: Number(deliveryAddress.latitude), lng: Number(deliveryAddress.longitude) } : undefined,
    [deliveryAddress],
  )

  const center = useMemo(() => {
    if (myPosition && workshop) {
      const lat_c = (Number(workshop.latitude) + myPosition.lat) / 2
      const lng_c = (Number(workshop.longitude) + myPosition.lng) / 2
      return {
        lat: lat_c,
        lng: lng_c,
      }
    }
    return undefined
  }, [myPosition, workshop])

  useEffect(() => {
    if (vanPosition) {
      oldVanPosition.current = vanPosition
    }
  }, [vanPosition])

  const directionsCallback = useCallback(
    (result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
      if (result !== null) {
        if (status === 'OK') {
          setDirections(result)
        } else {
          setDirections(null)
        }
      }
    },
    [],
  )

  useEffect(() => {
    if (directions) {
      const route = directions.routes[0]
      const leg = route.legs[0]
      const distance = leg.distance?.value
      if (distance && distance < 5000 && !callQuote) {
        setCallQuote(true)
      }
    }
  }, [directions])

  const directionsServiceOptions = useMemo(() => {
    const shouldCallDirection =
      oldVanPosition.current?.latitude !== vanPosition?.latitude ||
      oldVanPosition.current?.longitude !== vanPosition?.longitude
    if (myPosition && vanLatLng && shouldCallDirection) {
      return {
        destination: { ...myPosition },
        origin: { ...vanLatLng },
        travelMode: google.maps.TravelMode.DRIVING,
      }
    } else return undefined
  }, [myPosition, vanLatLng, oldVanPosition, vanPosition])

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map)
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  useEffect(() => {
    if (map && myPosition && !isInitMap) {
      const bounds = new window.google.maps.LatLngBounds()
      if (myPosition) bounds.extend(myPosition)
      if (workshop) {
        bounds.extend({ lat: Number(workshop.latitude), lng: Number(workshop.longitude) })
      }
      if (vanLatLng) {
        bounds.extend({ lat: vanLatLng.lat, lng: vanLatLng.lng })
        setIsInitMap(true)
      }
      map.fitBounds(bounds)
    }
  }, [myPosition, vanLatLng, workshop, deliveryAddress, vanPosition, map])

  return (
    <>
      <GoogleMap
        id='my_map'
        mapContainerStyle={{
          height: showOnModal ? '100vh' : 'calc(100vh - 100px)',
          width: '100%',
        }}
        zoom={16}
        options={{
          zoomControl: false,
          fullscreenControl: false,
          scaleControl: false,
          rotateControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        center={center}
      >
        {loaded && (
          <>
            {!!myPosition && <MarkerF key='my' position={myPosition} />}
            {!!workshop && (
              <>
                <MarkerF
                  key='my'
                  position={{ lat: Number(workshop.latitude), lng: Number(workshop.longitude) }}
                  icon={{ url: '/img/shop-marker.svg' }}
                />
                {!isOnTheWay && (
                  <>
                    <MarkerF
                      key='workshop'
                      position={{ lat: Number(workshop.latitude), lng: Number(workshop.longitude) }}
                      icon={{ url: '/img/shop-marker.svg' }}
                    />
                    <MarkerF
                      key='vanStart'
                      position={{ lat: Number(workshop.latitude), lng: Number(workshop.longitude) }}
                      icon={{ url: '/img/van-car.svg', anchor: new window.google.maps.Point(10, 34) }}
                    />
                    <InfoWindowF
                      position={{ lat: Number(workshop.latitude), lng: Number(workshop.longitude) }}
                      options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                      onCloseClick={() => null}
                    >
                      <Box display='flex' flexDirection='column'>
                        <Typography fontSize={14} mt={1}>
                          Mobile technician day <br /> starts from our workshop: <br />
                          {workshop.name}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '90px',
                            height: '40px',
                            transform: 'scale(0.3)',
                          }}
                        >
                          <LicensePlate
                            licenseNumber={vanNumber || ''}
                            model={''}
                            handleVehInputChange={() => {}}
                            disabled
                          />
                        </Box>
                      </Box>
                    </InfoWindowF>
                  </>
                )}
              </>
            )}
            {!!vanLatLng && (
              <>
                <MarkerF key='van' position={vanLatLng} icon={{ url: '/img/van-car.svg' }} />
                {directionsServiceOptions && (
                  <DirectionsService options={directionsServiceOptions} callback={directionsCallback} />
                )}
                {directions && !!vanLatLng && (
                  <DirectionsRenderer
                    directions={directions}
                    options={{
                      polylineOptions: {
                        strokeColor: '#4285F4',
                      },
                      markerOptions: {
                        opacity: 0,
                      },
                      suppressMarkers: true,
                      preserveViewport: true,
                    }}
                  />
                )}
                <InfoWindowF
                  position={vanLatLng}
                  options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                  onCloseClick={() => null}
                >
                  <Box display='flex' flexDirection='column'>
                    <Box height={30} sx={{ transform: 'scale(0.3) translate(-10px, 0px)' }} width={100} ml='2px'>
                      <LicensePlate
                        licenseNumber={vanNumber || ''}
                        model={''}
                        handleVehInputChange={() => {}}
                        disabled
                      />
                    </Box>
                    <Typography fontSize={14}>{technicianName}</Typography>
                  </Box>
                </InfoWindowF>
              </>
            )}
          </>
        )}
      </GoogleMap>

      <Box
        sx={{
          position: 'absolute',
          left: { xs: 10, lg: 24 },
          top: { xs: 55, lg: 24 },
          width: { xs: 'calc(100vw - 20px)', lg: 476 },
          backgroundColor: '#ece8fe',
          borderRadius: 2,
          px: 4,
          py: 3,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#4522C2',
          display: 'flex',
          gap: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: '#090221',
            color: 'white',
            width: 20,
            height: 20,
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          i
        </Box>
        <Typography className='text-grey flex-1' sx={{ color: '#081F44' }}>
          On delivery day, the technician&rsquo;s location will also be on the map so you can track it live.
        </Typography>
      </Box>
    </>
  )
}

const MapTrackTechnician = React.memo(MapTrackTechnicianComponent)

export default MapTrackTechnician
