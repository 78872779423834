import { FC } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Modal } from '@mui/material'
import { Box } from '@mui/system'
import { useJsApiLoader } from '@react-google-maps/api'
import { GOOGLE_MAP_API_KEY } from 'src/core/constants'
import { VanPosition } from 'src/core/models/van.model'
import { Address, Workshop } from '@glass/models'
import MapTrackTechnician from './MapTrackTechnician'

type PropComponent = {
  showOnModal?: boolean
  onDismiss?: () => void
  deliveryAddress: Address
  workshop: Workshop
  vanPosition: VanPosition | null
  vanNumber?: string
  technicianName?: string
  isOnTheWay?: boolean
  setCallQuote: (value: boolean) => void
  callQuote: boolean
}

export const TrackTechnician: FC<PropComponent> = ({
  showOnModal = true,
  onDismiss = () => undefined,
  deliveryAddress,
  workshop,
  vanPosition,
  vanNumber,
  technicianName,
  isOnTheWay,
  ...props
}) => {
  if (!GOOGLE_MAP_API_KEY) return <></>
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  })

  if (!isLoaded) return <div></div>
  return (
    <>
      {showOnModal && (
        <Modal
          open={showOnModal}
          aria-labelledby='child-modal-title'
          disableAutoFocus={true}
          aria-describedby='child-modal-description'
        >
          <Box width='100vw' height='100vh' className='p-0'>
            <Button
              variant='outlined'
              startIcon={<ArrowBackIcon />}
              sx={{
                position: 'absolute',
                top: 10,
                left: 10,
                zIndex: 99,
                backgroundColor: 'white',
                color: 'black',
                border: 'transparent',
              }}
              style={{ boxShadow: '0px 2px 6px 2px #00000026' }}
              onClick={onDismiss}
              type='button'
            >
              Back
            </Button>
            <MapTrackTechnician
              {...props}
              workshop={workshop}
              deliveryAddress={deliveryAddress}
              showOnModal={showOnModal}
              vanPosition={vanPosition}
              vanNumber={vanNumber}
              technicianName={technicianName}
              isOnTheWay={isOnTheWay}
            />
          </Box>
        </Modal>
      )}
      {!showOnModal && (
        <Box sx={{ position: 'relative' }}>
          <MapTrackTechnician
            {...props}
            workshop={workshop}
            deliveryAddress={deliveryAddress}
            vanPosition={vanPosition}
            vanNumber={vanNumber}
            technicianName={technicianName}
            isOnTheWay={isOnTheWay}
          />
        </Box>
      )}
    </>
  )
}
