import { ApiResponse, VanPosition } from '@glass/models'
import { fetchApi } from './api.service'

export const getVanPosition = async (traccarId: string): Promise<ApiResponse<VanPosition[]>> => {
  const email = process.env.REACT_APP_VAN_CHECKING_EMAIL
  const password = process.env.REACT_APP_VAN_CHECKING_PASSWORD
  const credentials = `${email}:${password}`
  const encodedCredentials = btoa(credentials)
  return await fetchApi(`${process.env.REACT_APP_TRACCAR_URL}/api/positions?deviceId=${traccarId}`, {
    headers: {
      Authorization: `Basic ${encodedCredentials}`,
      'Content-Type': 'application/json',
    },
  })
}
